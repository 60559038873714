<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.Questions") }}</h1>
        <v-expansion-panels id="exp-pan">
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t("message.Quest2") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-dialog v-model="dialog1" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="auto"
                    width="250"
                    v-on="on"
                    :src="getImageUrlbyName('AskP2')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('AskP2')"> </v-img>
                </v-card>
              </v-dialog>
              <!-- <v-img
                width="400"
                class="white--text align-end"             
                :src="getImageUrlbyName('AskP2')"
              >
              </v-img> -->
            
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-for="(item, i) in items" :key="i">
            <v-expansion-panel-header>
              {{ $t(item.name) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t(item.text) }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
          <v-btn v-on="on" small id="buy-btn">{{ $t("message.ConsultBtn") }} </v-btn>
          <div id="history-card-text">
            {{ $t("message.HaveQuest") }}
          </div>
        </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
        <v-col></v-col>
      </v-card>
    </div>
  </div>
</template>

<script>
import Feedback from '../../../components/Feedback';
import InformsDrawer from "../../../views/Navigations/Informs/InformsDrawer";
export default {
  data: () => ({
    items: [
      { name: "message.Quest1", text: "message.QuestText1" },
      // { name: "message.Quest2", text: "message.QuestText2" },
      { name: "message.Quest3", text: "message.QuestText3" },
      { name: "message.Quest4", text: "message.QuestText4" },
      { name: "message.Quest5", text: "message.QuestText5" },
    ],
  }),
  components: {
    InformsDrawer,
    Feedback,
  },
  // async mounted() {
  //   await this.getPictureblock();
  // },
  // methods: {
  //   async getPictureblock() {
  //     await this.$store.dispatch("getAllPictureblocks");
  //   },
  //   getImageUrlbyName(name, img) {
  //     img = this.$store.getters.getPictureblocks.find(
  //       (item) => item.name == name
  //     );
  //     console.log(img);
  //     return "data:image/jpeg;base64," + img.picture;
  //   },
  // },
};
</script>

<style>
#exp-pan {
  padding-top: 20px;
}
</style>